<template>
  <div
    class="block-loader"
    :class="{
      'is-loading': loading,
      'has-shadow': hasShadow,
    }"
  >
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hasShadow: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.block-loader {
  opacity: 1;
  transition: all 0.2s;
  position: relative;
  width: 100%;
  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    background-color: #eee;
    opacity: 0;
    transition: all 0.2s;
  }

}
.is-loading {
  &::after {
    opacity: 0.4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}

.is-loading.has-shadow {
   &::after {
    background: linear-gradient(
      100deg,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, .5) 50%,
      rgba(0, 0, 0, 0) 60%
    ) #e2e2e2;
    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s loading ease-in-out infinite;
  }
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
</style>
